import "./ContactUs.scss";

import React from "react";

const ContactUs = () => {
  return (
    <div className="contact-outer-container">
      <h2>Contact Us</h2>
    </div>
  );
};

export default ContactUs;
