import "./Offices.scss";

import React from "react";

const Offices = () => {
  return (
    <div className="offices-outer-container">
      <h2 className="font-extrabold">Offices</h2>
      <hr />
      <div className="office-container">
        <h3>Kash Technologies</h3>
        <p>2/B, Karoli Nagar</p>
        <p>Ganga Nagar, Dewas</p>
        <p>Madhya Pradesh, 455001</p>
      </div>
    </div>
  );
};

export default Offices;
