import "./About.scss";

const About = () => {
  return (
    <div className="about-outer-container">
      <h2 className="font-extrabold">About</h2>
      <p>
        We are building a e-commerce platform that will connect small producers
        to a broad consumer base.
      </p>
    </div>
  );
};

export default About;
