import "./TnC.scss";

const TnC = () => {
  return (
    <div className="tnc-outer-container">
      <h3>Date: 23 June 2024</h3>
      <h2>Terms and Conditions</h2>
      <p>
        Welcome to ShooraMall! These Terms and Conditions ("Terms") govern your
        use of our website located at www.shooramall.com (together or
        individually "Service") operated by Kash Technologies ("Kash"). Kash has
        it's registered office at 2/B, Karoli Nagar, Dewas, Madhya Pradesh -
        455001. The use of this website is subject to the following terms of
        use:
      </p>
      <p>
        By accessing or using our Service, you agree to be bound by these Terms.
        If you disagree with any part of the Terms, then you do not have
        permission to access the Service.
      </p>

      <p>
        Use of any information or materials on www.shooramall.com. is entirely
        at your own risk, for which we shall not be liable. It shall be your own
        responsibility to ensure that any products, services or information
        available through this website meet your specific requirements.
        www.shooramall.com. website contains material which is owned by or
        licensed to us. This material includes, but is not limited to, the
        design, layout, look, appearance and graphics. Reproduction is
        prohibited other than in accordance with the copyright notice, which
        forms part of these terms and conditions.
      </p>
      <p>
        Neither we nor any third parties provide any warranty or guarantee as to
        the accuracy, timeliness, performance, completeness or suitability of
        the information and materials found or offered on www.shooramall.com for
        any particular purpose. You acknowledge that such information and
        materials may contain inaccuracies or errors and we expressly exclude
        liability for any such inaccuracies or errors to the fullest extent
        permitted by law.
      </p>
      <p>
        Unauthorized use of www.shooramall.com. may give rise to a claim for
        damages and/or be a criminal offence.
      </p>
    </div>
  );
};

export default TnC;
