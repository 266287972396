import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-outer-container">
      <h3>Date: 23 June 2024</h3>
      <h2>Privacy Policy</h2>
      <p>
        Kash Technologies ("we", "our", "us") is committed to protecting your
        privacy. This Privacy Policy explains how your personal information is
        collected, used, and disclosed by us. This Privacy Policy applies to our
        website, www.shooramall.com, and its associated subdomains
        (collectively, our "Service"). By accessing or using our Service, you
        signify that you have read, understood, and agree to our collection,
        storage, use, and disclosure of your personal information as described
        in this Privacy Policy.
      </p>
      <p>
        <h4>Personal Information</h4>
        <br /> We collect personal information that you provide to us when you
        register on our website, make a purchase, subscribe to our newsletter,
        or contact us. This information may include: Name Email address Mailing
        address Phone number Payment information{" "}
      </p>
      <p>
        <h4>Use and Disclosure</h4>
        <br />
        Personal Information collected never disclosed with other parties and
        keep preserve in a highly confidential manner. Information shares with
        Authorized Third Party Service Providers (sending SMS, Sending Mails)
        only. These “Third Party Service Providers” perform functions on our
        behalf, like sending out and distributing our administrative and
        promotional emails. We may share your Personal Information with such
        Service Providers to deliver packages, send email, provide marketing
        assistance, provide search results and links, operate the Website,
        troubleshoot, and provide customer service. You agree not to submit to
        the Company (whether via this site or by any other means) any materials
        which: a: libel, defame, invade privacy, or are obscene, pornographic or
        harassing; b: infringe any intellectual property rights, including
        copyrights, of any third party; c: violate any law or regulation; d:
        advocate illegal activity or e: advertise or otherwise solicit funds or
        are a solicitation for goods or services." Limitation of Damages:
      </p>
      <p>
        <h4>Usage Data</h4>
        <br /> We may also collect information on how the Service is accessed
        and used. This usage data may include information such as your
        computer's Internet Protocol (IP) address, browser type, browser
        version, the pages of our Service that you visit, the time and date of
        your visit, the time spent on those pages, and other diagnostic data.{" "}
      </p>
      <p>
        {" "}
        <h4>How We Use Your Information</h4>
        <br></br> We use the information we collect in various ways, including
        to: Provide, operate, and maintain our Service Improve, personalize, and
        expand our Service Understand and analyze how you use our Service
        Develop new products, services, features, and functionality Process your
        transactions and manage your orders Send you emails and other
        communications, including updates, promotional offers, and newsletters
        Find and prevent fraud Comply with legal obligations{" "}
      </p>
      <p>
        {" "}
        <h4>Sharing Your Information</h4>
        <br></br> We may share your personal information with: Service
        Providers: We may share your personal information with third-party
        service providers to perform services on our behalf, such as payment
        processing, data analysis, email delivery, hosting services, customer
        service, and marketing assistance. Business Transfers: We may share or
        transfer your information in connection with, or during negotiations of,
        any merger, sale of company assets, financing, or acquisition of all or
        a portion of our business to another company. Compliance with Laws: We
        may disclose your information where we are legally required to do so in
        order to comply with applicable law, governmental requests, a judicial
        proceeding, court order, or other legal process.{" "}
      </p>
      <p>
        {" "}
        <h4>Security of Your Information</h4>
        <br></br> We use administrative, technical, and physical security
        measures to help protect your personal information. While we have taken
        reasonable steps to secure the personal information you provide to us,
        please be aware that despite our efforts, no security measures are
        perfect or impenetrable, and no method of data transmission can be
        guaranteed against any interception or other types of misuse.{" "}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
