import "./ShippingPolicy.scss";

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy-outer-container">
      <h3>Date: 23 June 2024</h3>
      <h2>Shipping Policy</h2>
      <p>
        Welcome to ShooraMall! This Shipping Policy explains the terms and
        conditions that govern the shipping of products purchased from our
        website, www.shooramall.com. By placing an order with us, you agree to
        the terms of this Shipping Policy.
      </p>
      <p>
        <h4>Processing Time</h4>
        <br /> All orders are processed within 7-14 business days (excluding
        weekends and holidays) after receiving your order confirmation email.
        You will receive another notification when your order has shipped.
      </p>
      <p>
        <h4>Shipping Rates and Delivery Estimates</h4>
        <br />
        We do not charge for shipping. Shipping is free.
      </p>
      <p>
        <h4>Damages and Lost Packages</h4>
        <br /> is not liable for any products damaged or lost during shipping.
        If you received your order damaged, please contact the shipment carrier
        or our support team directly to file a claim. Please save all packaging
        material and damaged goods before filing a claim.
      </p>
      <p>
        <h4>Shipping Address</h4>
        <br /> Please ensure that your shipping address is correct before
        submitting your order. We are not responsible for orders shipped to
        incorrect addresses provided by the customer.
      </p>
      <p>
        <h4>Changes to Shipping Policy</h4>
        <br /> We reserve the right to update or change this Shipping Policy at
        any time. Any changes will be posted on this page with an updated
        effective date. Contact Us If you have any questions about this Shipping
        Policy, please contact us: Email: support@shooramall.com. Thank you for
        shopping with ShooramMall!
      </p>
    </div>
  );
};

export default ShippingPolicy;
