import "./RefundPolicy.scss";

const RefundPolicy = () => {
  return (
    <div className="refund-policy-outer-container">
      <h3>Date: 23 June 2024</h3>
      <h2>Refund Policy</h2>
      <p>
        At ShooraMall, we are committed to providing quality products and
        services to our customers. If you are not entirely satisfied with your
        purchase, we are here to help. This Refund Policy outlines the
        conditions under which refunds and returns are accepted.
      </p>
      <p>
        <h4>Returns</h4>
        <br /> You have 7 calendar days to return an item from the date you
        received it. To be eligible for a return, your item must be unused and
        in the same condition that you received it. Your item must be in the
        original packaging and must include the receipt or proof of purchase.
      </p>
      <p>
        <h4>Refunds</h4> <br />
        Once we receive your item, we will inspect it and notify you that we
        have received your returned item. We will immediately notify you on the
        status of your refund after inspecting the item. If your return is
        approved, we will initiate a refund to your original method of payment.
        You will receive the credit within 7 days.
      </p>
      <p>
        <h4>Late or Missing Refunds</h4>
        <br /> If you haven’t received a refund yet, first check your bank
        account again. Next, contact your bank. There is often some processing
        time before a refund is posted. If you’ve done all of this and you still
        have not received your refund yet, please contact us at
        support@shooramall.com.
      </p>
    </div>
  );
};

export default RefundPolicy;
